  <template>
  <v-card>
    <v-card-text>
      <v-btn
        class="float-right"
        to="/fkadmin"
      >
        Admin Dashboard
      </v-btn>
      <h2>Members</h2>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="users"
      item-key="uid"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.uuid="{ item }">
        {{ item.name }}<br>
        <small>({{ item.uid }})</small>
      </template>

      <template v-slot:item.silverKombatCoins="{ item }">
        {{ item.silverKombatCoins|toCurrencyNoDec }}<br>
      </template>
      <template v-slot:item.modified="{ item }">
        <span v-if="item.modified">
          {{ item.modified.seconds|moment("dddd MMM Do") }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/functions'
import dbFunctions from '@/db'

export default {
  components: {},

  data() {
    return {
      search: '',
      users: [],
    }
  },
  computed: {
    headers() {
      return [
        {text: 'UUID', value: 'id'},
        {text: 'Name', value: 'name'},
        {text: 'Email', value: 'email'},
        {text: 'Overall Rank', value: 'rank'},
        {text: 'Last Event Rank', value: 'lastPointsRank'},
        {text: 'Total Points', value: 'totalPoints'},
        {text: 'Last Points', value: 'lastPoints'},
        {text: 'Silver KombatCoins', value: 'silverKombatCoins'},
        {text: 'Gold KombatCoins', value: 'goldKombatCoins'},
        {text: 'Teams', value: 'teams'},
        {text: 'Country', value: 'country'},
        {text: 'Sessions', value: 'sessions'},
        {text: 'Browser', value: 'browser'},
        {text: 'Wallet', value: 'solanaAddress'},
      ]
    },
  },
  mounted() {
    this.getMembers()
  },
  methods: {
    getMembers() {
      dbFunctions.getAllUserProfile().then(querySnapshot => {
        this.users = []
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          this.users.push(data)
        })
        for (let i = 0; i < this.users.length; i += 1) {
          this.userItem(this.users[i].userUID, i)
        }
      })
    },
    userItem(userUid, i) {
      let memberData = {}
      const member = firebase.functions().httpsCallable('getUser')
      member({uid: userUid})
        .then(user => {
          memberData = user
          this.users[i].name = memberData.data.displayName
          this.users[i].email = memberData.data.email

          return memberData.data.displayName
        })

      return memberData
    },
  },
}
</script>

<style lang="scss">
</style>
